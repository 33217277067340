<template>
  <div class="text-black mt-5">
    <AppPushNotification ref="notification" :title="notificationModel.title" :text="notificationModel.text"
      :color="notificationModel.color" />
    <div class="text-center" v-if="orderComplete === true" style="margin-top: 150px">
      <img src="https://cdn-icons-png.flaticon.com/512/4594/4594686.png" class="img-fluid" width="50%" alt="" />
      <h6 class="mt-4 font-weight-bold">{{ $t('endOfTransection') }}</h6>
      <small>{{ $t('endOfTransectionDialog') }}</small>
    </div>
    <div v-else>
      <div v-if="loadingPage == true" class="mt-5 pt-2">
        <ul class="list-group list-group-flush mt-3" style="margin-bottom: 250px">
          <li class="list-group-item pr-0 pl-0" v-for="(list, index) in receiptItems" :key="index">
            <table style="width: 100%" aria-describedby="">
              <tr>
                <td style="width: 20%" valign="top" align="left">
                  <div v-if="list.remoteImagePath === null ||
      list.remoteImagePath === undefined
      " :style="{
      'background-color': getImgColor(list.indexColor),
      'border-radius': '5px',
      width: '90px',
      height: '90px',
    }"></div>
                  <div v-else :style="{
      'background-image': `url('${noImg}')`,
      'background-size': 'cover',
      'background-position': 'center center',
      'border-radius': '5px',
      width: '90px',
      height: '90px',
    }"><img class="image-inbox" :src="getImageURL(list.remoteImagePath, '100')"
                      @error="setErrorImg($event, list.remoteImagePath)"></div>
                </td>
                <td class="pl-2" style="font-size: medium;" valign="top">
                  <div class="d-flex justify-content-between">
                    <div v-if="list.productPLU != undefined">
                      <h6 class="font-weight-light">
                        {{ list.productPLU.name }}
                        <br />
                      </h6>
                      <!-- {{ list.productPLU.SKURatio }} {{ list.productPLU.unit.name || '' }}<br> -->
                      <div v-if="list.orderedOptions !== undefined">
                        <div v-if="list.orderedOptions.length > 0">
                          <div style="padding-bottom: 0px" v-for="ordered in list.orderedOptions"
                            :key="ordered.objectId" class="p-0">
                            <small>
                              • {{ ordered.optionItemName }}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div v-if="list.note">
                        <small class="text-description">
                          {{ $t('note') }} : {{ list.note }}
                        </small>
                      </div>
                    </div>
                    <div v-if="list.deleted_at == undefined">
                      <span style="font-weight: lighter; font-size:small;padding: 1.5cap;"
                        :class="getOrderStatusColor(list.status)">
                        {{ getOrderStatus(list.status) }}
                      </span>
                    </div>
                    <div v-else>
                      <span style="font-weight: lighter; font-size:small;padding: 1.5cap;"
                        :class="getOrderStatusColor('C')">
                        {{ getOrderStatus('C') }}
                      </span>
                    </div>
                  </div>
                  <table style="width: 100%" aria-describedby="" class="mt-2">
                    <tr>
                      <td style="width: 50%">
                        <h5 class="mb-0 font-weight-bold text-grey">
                          x{{ list.quantity }}
                        </h5>
                        <small class="text-description">
                          {{ $t('orderTime') }} :
                          {{ showCreatedAtTime(list.created_at) }}

                        </small>
                      </td>

                      <td class="text-right">
                        <h5 v-if="list.productSKU != undefined" class="mb-0">
                          ฿{{ SKUPrice(list.netAmount) }}
                        </h5>
                        <small class="text-white">-</small>
                        <small class="text-description">{{ $t('orderedBy') }} {{ list.ordered_by }}</small>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </li>
        </ul>

        <footer class="fixed-bottom"
          style="padding: 20px; background-color: #ffffff;box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.05);">
          <div class="text-description text-center mb-3" style="font-size: large;">{{ $t('orderPreparing') }}<br>{{
      $t('orderPreparing2') }}</div>
          <div class="row p-2  text-dark font-weight-bold">
            <div class="col font-weight-bold">
              <h5> {{ itemCount }} {{ $t('item') }}</h5>
            </div>
            <div class="col">
              <h5 class="font-weight-bold text-right">฿{{ grandTotal }}</h5>
            </div>
          </div>

          <div class="row">
            <div class="col-6 pr-0">
              <div>
                <CButton :disabled="loading" @click="pushRouter('/dine-in/menulists')" color="dark"
                  style="font-size: 20px;height: 100%;border-radius: 30px; " block>
                  <CSpinner v-if="loading" class="mb-1" component="span" size="sm" aria-hidden="true" /> &nbsp; <span>{{
      $t('backToOrder') }} </span>
                </CButton>
              </div>

            </div>
            <div class="col-6">
              <CButton color="success" variant="outline" block
                style="font-size: 20px;height: 100%;border-radius: 30px; " @click="checkOut()">
                <span> {{ $t('checkOut') }} </span>
              </CButton>
            </div>
          </div>
        </footer>
      </div>
      <div class="text-center mt-5" v-if="loadingPage == false">
        <div class="no-data-container">
          <div class="text-center p-4">
            <img src="/img/receipt_empty.png" class="img-fluid " width="40%" alt="" />
            <h4 class="mt-4 font-weight-bold text-dark">{{ $t('emptyCartDialog') }}</h4>
            <span class="text-description">{{ $t('emptyCartDialogDespt') }}</span><br>
            <router-link to="/dine-in/menulists">
              <CButton style="width: 200px;" class="btn-color-theme mt-3 ">
                <strong class="h5"> {{ $t('backToMenu') }} </strong>
              </CButton>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import util from '@/util/util'
import poscrm from '@/services/poscrm'
import qs from 'qs'
import moment from 'moment'
import '@/util/menu.css'
import AppPushNotification from '@/components/AppPushNotification'

export default {
  components: {
    AppPushNotification
  },
  data() {
    return {
      receiptObjectId: '',
      receiptId: '',
      receiptItems: [],
      receiptStatus: '',
      table: {},
      orderComplete: false,
      loading: false,
      loadingPage: null,
      paymentStatus: '',
      notificationModel: {
        color: '',
        title: '',
        text: '',
      },
      itemCount: 0,
    }
  },
  computed: {
    ...mapGetters(['shopLineOA']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopObjectId() {
      return `${localStorage.getItem('shopObjectId')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopObjectId
      )
    },
    shopName() {
      if (this.shop) {
        return this.shop.shopName
      } else {
        return ''
      }
    },

    grandTotal() {
      let total = this.receiptItems.reduce((accum, product) => {
        return accum + ((product.deleted_at == undefined) ? product.totalAmount : 0)
      }, 0)

      return util.convertCurrency(total)
    },
    tableName() {
      return this.table?.name
    },
    tableObjectId() {
      return this.table?.objectId
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
    PayPermission() {
      let PayPermission = localStorage.getItem('paymentStatus') || false
      return PayPermission
    },
  },
  created() {
    this.receiptObjectId = localStorage.getItem('receiptObjectId') || ''
    this.receiptId = localStorage.getItem('receiptId') || ''
    this.table = JSON.parse(localStorage.getItem('table'))
    this.getTableStatusFromFB()
    this.loading = false
  },
  methods: {
    ...util,
    setErrorImg(event, image) {
      if (event.target.src == image) {
        event.target.src = this.noImg
      } else {
        event.target.src = image
      }
    },
    pushRouter(path) {
      this.loading = true
      this.$router.push(path)
    },
    showNotification(title, text, color) {
      this.notificationModel = {
        color: color,
        title: title,
        text: text,
      }
      this.$refs.notification.pushNotification();
    },
    getOrderStatus(status) {
      switch (status) {
        case 'N':
        case 'ออเดอร์ใหม่':
          return this.$i18n.t('newOrder')
        case 'P':
        case 'กำลังทำ':
          return this.$i18n.t('OnPreparing')
        case 'C':
        case 'ยกเลิก':
          return this.$i18n.t('cancel')
        case 'S':
        case 'เสิร์ฟแล้ว':
          return this.$i18n.t('served')
        case 'A':
        case 'เรียบร้อย':
          return this.$i18n.t('OnSuccess')
        default:
          return ''
      }
    },
    getOrderStatusColor(status) {
      switch (status) {
        case 'N':
        case 'ออเดอร์ใหม่':
          return 'badge badge-info'
        case 'P':
        case 'กำลังทำ':
          return 'badge badge-warning'
        case 'C':
        case 'ยกเลิก':
          return 'badge badge-danger'
        case 'S':
        case 'เสิร์ฟแล้ว':
          return 'badge badge-served'
        case 'A':
        case 'เรียบร้อย':
          return 'badge badge-success'
        default:
          return ''
      }
    },
    showCreatedAtTime(time) {
      if (time) {
        return moment(time).format('HH:mm')
      } else {
        return ''
      }
    },
    grandTotalByQuantity(amount, quantity) {
      let grand = Number(amount) * Number(quantity)
      return util.convertCurrency(grand)
    },
    getImgColor(indexColor) {
      return util.generateColor(indexColor)
    },
    SKUPrice(price) {
      return util.convertCurrency(Number(price))
    },
    convertCurrency(num) {
      if (typeof num == 'number') {
        return '(+' + util.convertCurrency(Number(num)) + ')'
      } else {
        return ''
      }
    },
    async getReceiptTransaction() {
      try {
        await poscrm
          .get('/api/v1.1/' + this.uid + '/Receipt/data/' + this.receiptObjectId)
          .then((res) => {
            if (res.data?.data?.document != null) {
              let document = {}
              document = res.data.data.document
              this.receiptItems = document.receiptItems
              this.receiptStatus = document.receiptStatus
              this.receiptObjectId = document.objectId
              this.receiptId = document.id
              this.paymentStatus = document.table?.tableStatus || ''
              this.loadingPage = true
              if (document.receiptStatus === 'A') {
                this.orderComplete = true
                localStorage.clear()
                sessionStorage.clear()
              }

              if (this.receiptItems.length < 1) {
                this.loadingPage = false
              }

              if (this.paymentStatus == 'P') {
                this.$router.push('/dine-in/pending/payment')
              }


              if (document.deleted_at != null) {
                localStorage.clear()
                sessionStorage.clear()
                this.$router.push('/texp?errorCode=-1005')
              }
            }
          })
      } catch (error) {
        throw new Error(error)
      }

    },
    async updateOpenTable() {
      const uid = this.uid

      let data = {
        shopObjectId: this.shopObjectId,
        tableObjectId: this.table?.objectId,
        status: 1,
        receiptId: this.receiptId,
        receiptObjectId: this.receiptObjectId,
      }
      try {
        await poscrm({
          url: '/api/v1.1/' + uid + '/Shop/updatetablestatus',
          data: qs.stringify(data),
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then((res) => {
          sessionStorage.clear()
          if (this.orderComplete != true) {
            this.loading = false
            this.$router.push('/dine-in/menulists')
          }
        })
      } catch (error) {
        throw new Error(error)
      }

    },
    checkOut() {
      if (this.paymentStatus == 'P') {
        this.$router.push('/dine-in/pending/payment')
      } else {
        this.$router.push('/dine-in/paymentgateway')
      }

    },
    async getTableStatusFromFB() {
      let params = {
        shopObjectId: this.shopObjectId,
        objectId: this.tableObjectId,
      }
      try {
        await poscrm
          .get('/api/v1.1/' + this.uid + '/Shop/gettablebyobjectid/fbdata', {
            params: params,
          })
          .then((res) => {
            if (res.data?.data?.currentReceipt != undefined) {
              let currentReceipt = {}
              currentReceipt = res.data.data.currentReceipt
              this.itemCount = currentReceipt.orders
              this.receiptId = currentReceipt.id
              this.receiptObjectId = currentReceipt.objectId
              const date = new Date(res.data?.data?.updated_at || '');
              localStorage.setItem('updated_at', date.toLocaleTimeString())

              if (this.receiptId !== undefined && this.receiptId !== null) {
                localStorage.setItem('receiptId', this.receiptId)
                localStorage.setItem('receiptObjectId', this.receiptObjectId)
              }
              this.getReceiptTransaction()
            } else {
              this.loadingPage = false
              localStorage.removeItem('updated_at')
            }
            if (this.$route.query.redirect == 'pending') {
              this.showNotification(this.$t('orderSent'), this.$t('orderSentDespt'), 'success')
              const newQuery = { ...this.$route.query };
              delete newQuery.redirect;
              this.$router.replace({ query: newQuery });
            }
          })
      } catch (error) {
        throw new Error(error)
      }

    },
  },
}
</script>
<style scoped>
img.image-inbox {
  position: relative;
  object-fit: cover;
}
</style>
